<template>
  <div id="price-management-category">
    <app-sidebar-form
      id="category-edit"
      form-name="category"
      :title="$t('cancellation_policy.category.title')"
      :edition-mode-title="value"
      :edition="!!value"
      :entity="editedCategory"
      @hidden="clearCategory()"
      @reset="resetCategory()"
      @update:edition="!$event && $emit('input', null)"
      @update:entity="submitCategory()"
    >
      <template #fields>
        <b-row>
          <!-- CATEGORIES -->
          <b-col cols="12">
            <b-form-group :label="$t('navigation.categories')" label-for="categories">
              <category-tree-select
                id="categories"
                v-model="categories"
                :lang="$store.state.appConfig.lang"
                disable-branch-nodes
                @close="APIFetchCategoryProducts()"
              />
            </b-form-group>
          </b-col>

          <!-- PRODUCTS -->
          <b-col cols="12">
            <validation-provider #default="{ errors }" name="products Category" class="validation-required" rules="required">
              <b-form-group :label="$t('price_management.group.products')" label-for="products-category">
                <b-overlay :show="productsLoading" :variant="$store.state.appConfig.layout.skin === 'dark' ? 'transparent' : undefined">
                  <v-select
                    v-model="editedCategory.products"
                    input-id="products-category"
                    :placeholder="$t('price_management.group.products')"
                    multiple
                    :close-on-select="false"
                    :options="productsCategory"
                    :get-option-label="option => $options.filters.trans(option.name)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>

                  <b-form-checkbox
                    class="my-1"
                    :checked="editedCategory.products && editedCategory.products.length && editedCategory.products.length === productsCategory.length"
                    @change="selectAllProducts($event)"
                  >
                    {{ $t('price_management.group.select_all_products') }}
                  </b-form-checkbox>
                </b-overlay>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- HT PRICE -->
          <b-col cols="12" md="6">
            <app-input
              id="priceManagementBasePriceExcludingTaxes"
              v-model.number="editedCategory.basePriceExcludingTaxes"
              :label="$t('price_management.group.base_price')"
              :append="unities[editedCategory.basePriceMode]"
              type="number"
              step="0.01"
              rules="required"
            />
          </b-col>

          <!-- PRICE MODE -->
          <b-col cols="12" md="6">
            <enum-select
              id="priceManagementPriceMode"
              v-model="editedCategory.basePriceMode"
              :label="$t('service.price.mode')"
              enum-class="PriceMode"
              required
            />
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

import CategoriesTree from '@/views/category/CategoriesTree.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import CategoryTreeSelect from '@/components/CategoryTreeSelect.vue'
import { fetchCategoriesProductRequest } from '@/request/globalApi/requests/categoryRequests'

export default defineComponent({
  name: 'PriceManagementCategory',

  components: {
    CategoriesTree,
    AppSidebarForm,
    CategoryTreeSelect,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    category: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const { _cloneDeep, $store } = ctx.root
    const $emit = ctx.emit

    // Products data
    const productsLoading = ref(false)
    const productsCategory = ref([])

    // Category data
    const categories = ref([])
    const editedCategory = ref({})
    const oldCategory = ref({})

    // TODO: it's used everywhere, set it in global?
    const unities = {
      currency: $store.state.appConfig.currency,
      percent: '%',
    }

    // Fetch all products of many main categories
    const APIFetchCategoryProducts = () => {
      if (!categories.value.length) return

      productsLoading.value = true
      const queryParams = { categoriesIds: categories.value.map(cat => cat.id), packageTypology: false, mandatoryPackageTypology: false }

      fetchCategoriesProductRequest(queryParams).then(({ data }) => {
        productsCategory.value = data
        productsLoading.value = false
      })
    }

    const selectAllProducts = isCheckboxChecked => {
      if (isCheckboxChecked) editedCategory.value.products = productsCategory.value
      else editedCategory.value.products = []
    }

    const resetProducts = () => {
      productsCategory.value = []
    }

    const resetCategory = () => {
      editedCategory.value = _cloneDeep(oldCategory.value)
      categories.value = []
      resetProducts()
    }

    const clearCategory = () => {
      editedCategory.value = {}
      oldCategory.value = {}
      resetProducts()
    }

    watch(
      () => props.value,
      val => {
        editedCategory.value = _cloneDeep(props.category)
        oldCategory.value = _cloneDeep(props.category)

        if (['edit', 'duplicate'].includes(val)) {
          categories.value = [
            {
              id: editedCategory.value.categoryId,
              name: editedCategory.value.categoryName,
              children: [],
            },
          ]
          APIFetchCategoryProducts()
        } else if (!val) {
          categories.value = []
          clearCategory()
        }
      },
    )

    const submitCategory = () => {
      const normalizeCategory = {
        newCategory: editedCategory.value,
        oldCategory: oldCategory.value,
        categories: categories.value,
      }
      if (editedCategory.value.products[0]) {
        $emit('update:category', normalizeCategory)
        $emit('input', null)
        clearCategory()
      }
    }

    return {
      productsCategory,
      categories,
      editedCategory,
      oldCategory,
      unities,
      productsLoading,
      APIFetchCategoryProducts,
      selectAllProducts,
      resetCategory,
      clearCategory,
      submitCategory,
    }
  },
})
</script>
