<template>
  <div id="price-management">
    <b-card>
      <validation-observer ref="price-management-group-observer">
        <b-form ref="price-management-group-form">
          <b-row>
            <b-col cols="12" md="8">
              <b-row>
                <b-col cols="12" md="8" xl="10">
                  <app-input
                    id="priceManagementGroupName"
                    v-model="priceManagement.name"
                    :label="$t('price_management.group.name')"
                    :overlay="priceManagementLoading"
                    required
                  />
                </b-col>

                <b-col cols="12" md="4" xl="2">
                  <enum-select
                    id="priceManagementStatus"
                    v-model="priceManagement.status"
                    :label="$t('common.status')"
                    enum-class="PriceManagementStatus"
                    :overlay="priceManagementLoading"
                    :clearable="false"
                    :searchable="false"
                    required
                  >
                  </enum-select>
                </b-col>
              </b-row>

              <validation-provider #default="{ errors }" :name="$t('common.description')">
                <b-form-group :label="$t('common.description')" label-for="price-management-group-description">
                  <b-overlay :show="priceManagementLoading">
                    <b-form-textarea id="price-management-group-description" v-model="priceManagement.description" :placeholder="$t('common.description')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-overlay>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <PriceManagementCategories
      v-if="priceManagement && priceManagement.priceCategories && !priceManagementLoading"
      :price-categories.sync="editedPriceCategories"
    />
    <b-card class="mt-2">
      <b-row>
        <!-- Cancel -->
        <b-col class="d-flex justify-content-end">
          <b-button id="cancel-price-managements" type="link" :to="{ name: 'pricesManagement' }" variant="outline-secondary">
            <font-awesome-icon icon="ban" class="mr-50" />
            {{ $t('action.cancel') }}
          </b-button>
          <b-button
            id="submit-price-managements"
            type="submit"
            variant="primary"
            class="ml-2"
            :disabled="priceManagementLoading"
            @click.prevent="submitPriceManagement"
          >
            <font-awesome-icon icon="user-plus" class="mr-50" />
            {{ $route.params.copy ? $t('action.duplicate') : $t('action.submit') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { upperFirst, lowerCase, omit } from 'lodash'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { postPriceManagementRequest, patchPriceManagementRequest, fetchPriceManagementRequest } from '@/request/globalApi/requests/priceManagementRequests'
import PriceManagementCategories from './PriceManagementCategories.vue'

export default {
  name: 'PriceManagement',

  components: {
    PriceManagementCategories,
  },

  data() {
    return {
      priceManagementLoading: false,
      priceManagement: {
        id: null,
        name: '',
        description: '',
        priceCategories: [],
        status: 'active',
      },
      editedPriceCategories: [],
    }
  },

  computed: {
    ...mapGetters('priceManagement', ['priceManagementById']),
  },

  mounted() {
    // if edit or duplicate
    if (this.$route.name !== 'priceManagementAdd') {
      // if already loaded one time
      if (this.priceManagementById(this.$route.params.id)) {
        this.priceManagement = this.priceManagementById(this.$route.params.id)
        if (!this.priceManagement.priceCategories || this.priceManagement.priceCategories.length === 0) {
          this.editedPriceCategories = []
        } else {
          this.editedPriceCategories = this._cloneDeep(this.priceManagement.priceCategories)
        }
        // if duplicate
        if (this.$route.name === 'priceManagementDuplicate') {
          this.duplicatePriceManagement()
        }
      } else {
        // if never loaded
        this.priceManagementLoading = true
        fetchPriceManagementRequest(this.$route.params.id)
          .then(response => {
            this.priceManagement = response.data.priceManagement
            this.editedPriceCategories = this._cloneDeep(this.priceManagement.priceCategories)
            this.setPriceManagementsShowed(this.priceManagement)
          })
          .then(() => {
            // if duplicate
            if (this.$route.name === 'priceManagementDuplicate') {
              this.duplicatePriceManagement()
            }
          })
          .finally(() => {
            this.priceManagementLoading = false
          })
      }
    } else {
      this.editedPriceCategories = []
    }
  },

  methods: {
    ...mapActions('priceManagement', ['fetchPriceManagement', 'setPriceManagementsShowed', 'clearPriceManagementsShowed']),

    upperFirst,
    lowerCase,

    submitPriceManagement() {
      this.$refs['price-management-group-observer'].validate().then(isValid => {
        if (!isValid) return // TODO improve error message
        const editedPriceCategories = this.editedPriceCategories.map(editedPriceCategory => ({
          ...omit(editedPriceCategory, 'products'),
          productsIds: editedPriceCategory.products.map(product => product.id),
        }))

        const editedPriceManagement = {
          ...this.priceManagement,
          priceCategories: editedPriceCategories,
        }

        let action = patchPriceManagementRequest
        if (this.$route.name !== 'priceManagementEdit') {
          this.priceManagement.id = null
          action = postPriceManagementRequest
        }
        action(editedPriceManagement, editedPriceManagement.name).then(() => {
          this.$router.push({ name: 'pricesManagement', params: { refresh: true } })
        })
      })
    },

    duplicatePriceManagement() {
      this.priceManagement.name = `${this.priceManagement.name}_copy`
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].path !== '/prices-management') {
      this.clearPriceManagementsShowed()
    }
    next()
  },
}
</script>
